import React from 'react';
import women from '../../images/children.png';
import Header from '../../components/Header/Header';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import { useKarate } from '../../context/Context';
import './news.scss';
import NewsOne from '../../components/NewsOne/NewsOne';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.4,
    },
  },
};

function News() {
  const { news } = useKarate();

  return (
    <motion.section
      className="newsPage"
      initial="hidden"
      variants={pageVariants}
      animate="visible"
      exit="hidden"
    >
      <Header />
      <div className="news_top">
        <h1>Aktuality</h1>
        <img className="news_graphics" src={women} alt="silueta karatistů" />
      </div>
      <main className="news_main">
        {news?.loadComplete &&
          news.data.map((newsItem) => (
            <NewsOne
              title={newsItem.title}
              desc={newsItem.desc}
              photo={newsItem.photo}
              key={newsItem.id}
              id={newsItem.id}
            />
          ))}
      </main>
      <Footer />
    </motion.section>
  );
}
export default News;
