import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactPage from './pages/ContactPage/ContactPage';
import Documents from './pages/Documents/Documents';
import HomePage from './pages/HomePage.jsx/HomePage';
import News from './pages/News/News';
import NewsDetail from './pages/NewsDetail/NewsDetail';
import Successes from './pages/Successes/Successes';

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <>
      <ScrollToTop />

      <Switch>
        <Route path="/dokumenty">
          <Documents />
        </Route>
        <Route path="/aktuality/:preselectedNews">
          <NewsDetail />
        </Route>
        <Route path="/aktuality">
          <News />
        </Route>
        <Route path="/kontakt">
          <ContactPage />
        </Route>
        <Route path="/uspechy">
          <Successes />
        </Route>
        <Route path="/onas">
          <AboutUs />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </>
  );
}

export default App;
