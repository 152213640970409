import React, { useEffect } from 'react';
import belt from '../../../images/blackBelt.png';
import beltBrown from '../../../images/brownBelt.png';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './coachOne.scss';

const coachesVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

function CoachOne(props) {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="coachOne"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={coachesVariants}
    >
      <h4>{props.coachName}</h4>
      <div className="coachOne_top">
        <div className="coachOne_topLeft">
          <div className="coachOne_belt">
            {props.beltColor === undefined ? (
              <img src={belt} alt="ikona pásku" />
            ) : (
              <img src={beltBrown} alt="ikona hnědého pásku" />
            )}
            <div>
              <h5>{props.coachBelt}</h5>
              {props.coachBelt2 && <h5>{props.coachBelt2}</h5>}
            </div>
          </div>
          {props.coachTitle && (
            <p className="coachOne_title">{props.coachTitle}</p>
          )}
          <div className="coachOne_dates">
            <p>Narozen: {props.born}</p>
            <p>Karate trénuje od roku: {props.doingKarate}</p>
          </div>
        </div>
        <div
          className="coachOne_photoWrapper"
          style={{ backgroundImage: `url(${props.photoCoach})` }}
        ></div>
      </div>

      <h6>Kvalifikace</h6>
      <ul>
        <li>{props.qualification1}</li>
        {props.qualification2 && <li>{props.qualification2}</li>}
        {props.qualification3 && <li>{props.qualification3}</li>}
        {props.qualification4 && <li>{props.qualification4}</li>}
      </ul>
    </motion.div>
  );
}

export default CoachOne;
