import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './newsOne.scss';

const newsVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

function NewsOne(props) {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Link to={`/aktuality/${props.id}`} className="newsOne_a">
      <motion.div
        className="newsOne"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={newsVariants}
      >
        <div>
          <h3 className="newsOne_title">{props.title}</h3>
          <h4 className="newsOne_desc">{props.desc}</h4>
        </div>
        {props.photo && (
          <div
            className="newsOne_imgWrapper"
            style={{ backgroundImage: `url(${props.photo})` }}
          >
            {/*  <img src={props.photo} alt="ilustrační obrázek k aktualitě" /> */}
          </div>
        )}
      </motion.div>{' '}
    </Link>
  );
}

export default NewsOne;
