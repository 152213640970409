import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { motion } from 'framer-motion';
import './aboutUs.scss';
import Coaches from '../../components/Coaches/Coaches';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.4,
    },
  },
};

function AboutUs() {
  return (
    <motion.section
      className="aboutUs"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Header />
      <div className="aboutUs_top">
        <h1>O nás</h1>
        <div className="aboutUs_textMain">
          <p>
            Sportovní klub policie Hvězda Jirkov (SKPHJ) vznikl v září roku 2001.
            Zakládající členové poměrně dlouho hledali vhodný název nového
            klubu, aby nakonec zvolili ten, který odkazoval na příslušnost
            některých trenérů k Policii ČR.
          </p>
          <p>
            {' '}
            Od samého začátku se věnujeme výuce tradičních forem Karate-Do a
            sebeobrany, rozvoji všeobecné obratnosti a fyzické zdatnosti a
            klademe velký důraz na morálně volní vlastnosti členů klubu. Cílem
            našeho klubu je výchova zdatných cvičenců, slušných, cílevědomých a
            skromných lidí připravených pro život.
          </p>
        </div>
      </div>
      <main>
        <Coaches />
      </main>
      <Footer />
    </motion.section>
  );
}

export default AboutUs;
