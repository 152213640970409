import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import { useParams } from 'react-router';
import { useKarate } from '../../context/Context';
import './newsDetail.scss';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.4,
    },
  },
};

function NewsDetail() {
  const { news } = useKarate();
  const { preselectedNews } = useParams();
  const [newsDetail, setNewsDetail] = useState();

  useEffect(() => {
    news.loadComplete &&
      setNewsDetail(
        news.data.find((newsItem) => newsItem.id === parseInt(preselectedNews)),
      );
  }, [news.data, news.loadComplete, preselectedNews]);

  return (
    <motion.section
      className="newsDetail"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Header />
      {newsDetail && (
        <>
          <div className="news_top">
            <div>
              <h1>{newsDetail.title}</h1>
              <h3>{newsDetail.desc}</h3>
            </div>
            {newsDetail.photo && (
              <div
                className="newsDetail_imgWrapper"
                style={{ backgroundImage: `url(${newsDetail.photo})` }}
              >
                {' '}
              </div>
            )}
          </div>{' '}
          <main className="newsDetail_main">
            <p>{newsDetail.text}</p>
          </main>
        </>
      )}
      <Footer />
    </motion.section>
  );
}

export default NewsDetail;
