import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import email from '../../images/email.svg';
import phone from '../../images/phone.svg';
import './contactPage.scss';
import { motion } from 'framer-motion';

import Partners from '../../components/Partners/Partners';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.4,
    },
  },
};

function ContactPage() {
  return (
    <motion.section
      className="contacts"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Header />
      <div className="contacts_top">
        <h1>Kontakty</h1>
        <div className="contacts_topRight">
          <div>
            <img src={phone} alt="" />
            <p>+420 777 173 918</p>
          </div>
          <div>
            <img src={email} alt="" />
            <a
              className="contacts_email"
              href="mailto:skpkaratejirkov@seznam.cz"
            >
              skpkaratejirkov@seznam.cz
            </a>
          </div>
        </div>{' '}
      </div>
      <main className="contacts_main">
        <div className="contacts_mainLeft">
          <p>Trénujeme na 3. ZŠ v ulici Nerudova, Jirkov.</p>
          <p>
            <strong>Tréninky:</strong> Pondělí od 18.00 do 19.30 hodin, Středa
            od 18.00 do 19.30 hodin a Čtvrtek od 18.00 do 19.30 hodin
          </p>
          <p>
            <strong>Korespondenční adresa:</strong> SKP Hvězda Jirkov,
            Tkalcovská 1517, Jirkov 431 11
          </p>
          <p>
            <strong>IČO:</strong> 26541254
          </p>
          <p>
            <strong>Číslo účtu pro bezhotovostní platby:</strong>{' '}
            0808059339/0800 (do zprávy pro příjemce je nutné uvést jméno a
            příjmení za koho jsou platby hrazeny, děkujeme).
          </p>
          <span>
            <strong>Kontakty na trenéry:</strong>
            <p>
              Bc. Rudolf Jakeš: +420 777 173 918
              <br />
              Bc. Ladislav Kliment: +420 602 284 750
              <br />
              Tomáš Rác: +420 777 308 941
              <br />
              Dalibor Jech: +420 602 414 895
            </p>
          </span>
        </div>
        <div className="contacts_mainRight">
          <iframe
            title="Základní škola Jirkov, Nerudova 1151"
            className="contacts_map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10152.782667116147!2d13.440632!3d50.493316!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5c8820245d51ef53!2sZ%C3%A1kladn%C3%AD%20%C5%A1kola%20Jirkov%2C%20Nerudova%201151%2C%20okres%20Chomutov!5e0!3m2!1sen!2scz!4v1631199963147!5m2!1sen!2scz"
            allowFullScreen=""
            loading="lazy"
          ></iframe>{' '}
          <Partners />
        </div>
      </main>
      <Footer />
    </motion.section>
  );
}

export default ContactPage;
