import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { KarateProvider } from './context/Context';
import { AnimatePresence } from 'framer-motion';
import './index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <AnimatePresence exitBeforeEnter>
      <BrowserRouter>
        <KarateProvider>
          <App />
        </KarateProvider>
      </BrowserRouter>
    </AnimatePresence>
  </React.StrictMode>,
  document.getElementById('root'),
);
