import React from 'react';
import { Link } from 'react-router-dom';
import ig from '../../images/instagram.svg';
import fb from '../../images/facebook.svg';
import './footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer_left">
        <h4>Sportovní klub policie Hvězda Jirkov</h4>
        <p>3. ZŠ v ulici Nerudova, Jirkov</p>
      </div>
      <div className="footer_right">
        <p>
          <strong>TELEFON:</strong> +420 777 173 918
        </p>
        <p>
          <strong>EMAIL:</strong> skpkaratejirkov@seznam.cz
        </p>
      </div>{' '}
      <div>
        <Link className="footer_documents" to="/dokumenty">
          Dokumenty ke stažení{' '}
        </Link>
        <div className="footer_soMe">
          <a
            href="https://www.facebook.com/SKP.Hvezda.Jirkov/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="footer_soMe_icons" src={fb} alt="ikona facebooku" />
          </a>
          <a
            href="https://instagram.com/skp_hvezda_jirkov?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer_soMe_icons"
              src={ig}
              alt="ikona instagramu"
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
