import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { motion } from 'framer-motion';
import { useKarate } from '../../context/Context';
import './documents.scss';
import DocumentItem from '../../components/DocumentItem/DocumentItem';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.4,
    },
  },
};

function Documents() {
  const { documents } = useKarate();

  return (
    <motion.section
      className="documents"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Header />
      <div className="documents_top">
        <h1>Dokumenty ke stažení</h1>
      </div>
      <main className="documents_main">
        <h2>Organizační</h2>{' '}
        <div className="documents_mainDocType">
          {documents?.loadComplete &&
            documents.data
              .sort()
              .filter((docItem) => docItem?.docType?.selected === 0)
              .map((docItem) => (
                <DocumentItem
                  documentName={docItem.title}
                  documentUrl={docItem.doc}
                />
              ))}
        </div>
        <h2>Kata</h2>{' '}
        <div className="documents_mainDocType documents_mainDocType_kata">
          {documents?.loadComplete &&
            documents.data
              .sort()
              .filter((docItem) => docItem?.docType?.selected === 1)
              .map((docItem) => (
                <DocumentItem
                  documentName={docItem.title}
                  documentUrl={docItem.doc}
                />
              ))}
        </div>
      </main>
      <Footer />
    </motion.section>
  );
}

export default Documents;
