import React from 'react';
import download from '../../images/download.svg';
import './documentItem.scss';

function DocumentItem(props) {
  return (
    <a
      href={props.documentUrl}
      className="documentItem"
      target="_blank"
      rel="noreferrer"
    >
      <img src={download} alt="" />
      <p>{props.documentName}</p>
    </a>
  );
}

export default DocumentItem;
