import React, { useEffect, createContext, useContext, useReducer } from 'react';
import coachesJSON from '../content/coaches.json';

const DOMAIN = 'https://sbkadmin.cz/skphvezda';
export const ENDPOINT_DEF = DOMAIN + '/v1';
export const ENDPOINT_NEWS = DOMAIN + '/v1/public/aktuality';
export const ENDPOINT_DOCUMENTS = DOMAIN + '/v1/public/dokumenty';

const reducerInitialState = {
  data: {},
  isFetching: false,
  hasError: false,
  loadComplete: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isFetching: true,
        hasError: false,
        loadComplete: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        loadComplete: true,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const karateContext = createContext();

export const KarateProvider = ({ children }) => {
  const coaches = coachesJSON;
  const [news, dispatchNews] = useReducer(reducer, reducerInitialState);
  const [documents, dispatchDocuments] = useReducer(
    reducer,
    reducerInitialState,
  );

  useEffect(() => {
    // FETCH NEWS

    dispatchNews({
      type: 'FETCH_INIT',
    });
    fetch(ENDPOINT_NEWS, {
      headers: {},
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatchNews({
          type: 'FETCH_SUCCESS',
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchNews({
          type: 'FETCH_FAILURE',
        });
      });

    //FETCH DOCUMENTS
    dispatchDocuments({
      type: 'FETCH_INIT',
    });
    fetch(ENDPOINT_DOCUMENTS, {
      headers: {},
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatchDocuments({
          type: 'FETCH_SUCCESS',
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchDocuments({
          type: 'FETCH_FAILURE',
        });
      });
  }, []);
  return (
    <karateContext.Provider
      value={{
        coaches,
        ENDPOINT_DEF,
        news,
        dispatchNews,
        ENDPOINT_NEWS,
        documents,
        dispatchDocuments,
        ENDPOINT_DOCUMENTS,
      }}
    >
      {children}
    </karateContext.Provider>
  );
};

export const useKarate = () => useContext(karateContext);
