import React from 'react';
import './facebook.scss';

function Facebook() {
  return (
    <div
      style={{
        maxWidth: '100%',
        margin: '0 auto',
      }}
      id="facebook"
      className="fb-page"
      data-href="https://www.facebook.com/SKP.Hvezda.Jirkov/"
      data-tabs="timeline, events, messages"
      data-width="500px"
      data-height="500px"
      data-small-header="true"
      data-adapt-container-width="true"
      data-hide-cover="true"
      data-show-facepile="false"
    >
      <blockquote
        cite="https://www.facebook.com/SKP.Hvezda.Jirkov/"
        className="fb-xfbml-parse-ignore"
      >
        <a href="https://www.facebook.com/SKP.Hvezda.Jirkov/">
          SKP Hvězda Jirkov Facebook plugin
        </a>
      </blockquote>
    </div>
  );
}

export default Facebook;
