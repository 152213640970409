import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import logo from '../../images/logo.png';
import './header.scss';

function Header() {
  return (
    <header>
      <Link to="/">
        <img className="logo" src={logo} alt="logo klubu" />
      </Link>
      <Navbar />
    </header>
  );
}

export default Header;
