import React, { useEffect, useState } from 'react';
import mainGraphics from '../../images/home.png';
import Header from '../../components/Header/Header';
import { motion } from 'framer-motion';
import './homePage.scss';
import Facebook from '../../components/Facebook/Facebook';
import Footer from '../../components/Footer/Footer';
import NewsOne from '../../components/NewsOne/NewsOne';

import { useKarate } from '../../context/Context';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.4,
    },
  },
};

function HomePage() {
  const { news } = useKarate();
  const [latestNews, setLatestNews] = useState();

  useEffect(() => {
    news.loadComplete && setLatestNews(...news.data.slice(-1));
  }, [news.data, news.length, news.loadComplete]);

  return (
    <motion.section
      className="homePage"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Header />
      <div className="homePage_top">
        <h1>SKP Hvězda Jirkov</h1>
        <img
          className="homePage_graphics"
          src={mainGraphics}
          alt="silueta karatistů"
        />
      </div>
      <main>
        <div className="homePage_news">
          <h2>Aktuality</h2>
          {latestNews && (
            <NewsOne
              title={latestNews.title}
              desc={latestNews.desc}
              photo={latestNews.photo}
              key={latestNews.id}
              id={latestNews.id}
            />
          )}
        </div>
        {<Facebook /> && (
          <div className="homePage_soMe">
            <Facebook />
          </div>
        )}
      </main>
      <Footer />
    </motion.section>
  );
}

export default HomePage;
