import React from 'react';
import coaches from '../../content/coaches.json';
import './coaches.scss';
import CoachOne from './CoachOne/CoachOne';
import lada from '../../images/photos/lada.webp';
import huy from '../../images/photos/huy.webp';
import ruda from '../../images/photos/ruda.webp';
import tomas from '../../images/photos/tomas.webp';
import dalibor from '../../images/photos/dalibor.webp';

function Coaches() {
  const photos = {
    lada: { src: lada },
    huy: { src: huy },
    ruda: { src: ruda },
    tomas: { src: tomas },
    dalibor: { src: dalibor },
  };

  return (
    <div className="coaches_all">
      {Object.keys(coaches).map((coach, i) => (
        <CoachOne
          coachName={coaches[coach].name}
          coachBelt={coaches[coach].dan}
          coachBelt2={coaches[coach].dan2}
          coachTitle={coaches[coach].title}
          born={coaches[coach].born}
          doingKarate={coaches[coach].doingKarate}
          qualification1={coaches[coach].qualification1}
          qualification2={coaches[coach].qualification2}
          qualification3={coaches[coach].qualification3}
          qualification4={coaches[coach].qualification4}
          photoCoach={photos[coaches[coach].key].src}
          beltColor={coaches[coach].beltColor}
        />
      ))}
    </div>
  );
}

export default Coaches;
