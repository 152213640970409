import React from 'react';
import jirkov from '../../images/jirkov.jpeg';
import './partners.scss';

function Partners() {
  return (
    <section className="partners_section">
      <h3>Naši partneři:</h3>
      <div className="partners_all">
        <div className="partners_one">
          <a href="https://www.jirkov.cz/" target="_blank" rel="noreferrer">
            <img
              className="partners_logo"
              src={jirkov}
              alt="logo města Jirkov"
            />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Partners;
