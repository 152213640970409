import React from 'react';
import Footer from '../../components/Footer/Footer';
import { motion } from 'framer-motion';
import Header from '../../components/Header/Header';
import children from '../../images/child.png';
import './successes.scss';

const pageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.2,
    },
  },
};

function Successes() {
  return (
    <motion.section
      className="successes"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Header />
      <div className="successes_top">
        <h1>Úspěchy</h1>
        <img
          className="successes_graphics"
          src={children}
          alt="silueta karatistů"
        />
      </div>
      <main className="successes_main">Úspěchy</main>
      <Footer />
    </motion.section>
  );
}

export default Successes;
